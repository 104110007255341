import * as yup from "yup";


const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const signupValidators = yup.object({
	firstname: yup.string().trim().required("Il campo 'nome' è obbligatorio").matches(/^[^\d]*$/, "Il campo 'nome' è in un formato non valido"),
	lastname: yup.string().trim().required("Il campo 'cognome' è obbligatorio").matches(/^[^\d]*$/, "Il campo 'cognome' è in un formato non valido"),
	email: yup
		.string()
		.trim()
		.email("Inserisci un indirizzo email valido")
		.matches(emailRegex, "Inserisci un indirizzo email valido")
		.required("Il campo 'email' è obbligatorio"),
	password: yup
		.string().trim()
		.matches(/^(?=.{10,})/, "La password deve contenere almeno 10 caratteri")
		.matches(/(?=.*[A-Z])/, "La password deve contenere almeno un carattere maiuscolo")
		.matches(/(?=.*\d)/, "La password deve contenere almeno un numero")
		.required("Il campo 'password' è obbligatorio"),
	terms: yup.bool().oneOf([true], "Devi accettare i termini e le condizioni"),
	news_and_contact_partner: yup.bool(),
});
