import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import Cookies from "js-cookie";

import { Button, TextInput, Typography, useToast } from "design2impact";
import { redirectByRole } from "helpers2impact";

import resetPswValidator from "./validators";
import { FaCheck, FaTimes } from "react-icons/fa";

import { resetPassword } from "../../api/mutations";

import config from "../../config";

interface FormikInitialValues {
	password: string,
	confirmPassword: string
}

const formikInitialValues: FormikInitialValues = {
	password: "",
	confirmPassword: "",
};

const ResetPassword = () => {
	const { showToast } = useToast();

	const params = useLocation().search;
	const reset_token = new URLSearchParams(params).get("token");
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [passwordRequirementsMessage, setPasswordRequirementsMessage] = useState<string[]>([]);

	const { mutate: resetPasswordFn, isPending } = useMutation({
		mutationFn: resetPassword,
		onSuccess: (data) => {
			const domain = window.location.host.includes("localhost") ? "localhost" : ".start2impact.it";
			Cookies.set(config.cookieName, data.token, { expires: config.cookieExpirationDays, domain });
			redirectByRole(config.cookieName, config.adminUrl, config.talentUrl, config.coachUrl);
		},
		onError: () => showToast("Non è stato possibile completare l&apos;operazione. Il link potrebbe non essere più valido.", "error"),
	});

	const handleSubmit = async (data: FormikInitialValues) => {
		if (!executeRecaptcha || !reset_token) {
			showToast("Non è stato possibile completare l&apos;operazione. Il link potrebbe non essere più valido.", "error");
			return;
		}

		const recaptchaToken = await executeRecaptcha("resetPassword");
		resetPasswordFn({ token: reset_token, new_password: data.password, recaptcha: recaptchaToken });
	};

	const formik = useFormik({
		initialValues: formikInitialValues,
		onSubmit: (data) => {
			handleSubmit(data);
		},
		validationSchema: resetPswValidator,
	});

	useEffect(() => {
		resetPswValidator.validate({ password: formik.values.password }, { abortEarly: false })
			.then(() => {
				setPasswordRequirementsMessage([]);
			})
			.catch((errors) => {
				const passwordErrors = errors.inner
					.filter((error: any) => error.path === "password")
					.map((error: any) => error.message);

				setPasswordRequirementsMessage(passwordErrors);
			});
	}, [formik.values.password]);

	return (
		<div className="min-h-screen bg-ui-secondary">
			<div className="container">
				<div className="flex flex-col items-center pt-20 text-center">
					<img className="mx-auto mb-6" width="64" height="64" alt="Logo di start2impact" src="https://res.cloudinary.com/start2impact/image/upload/h_64/logo/Logo_University-12_wa6eoi.png" />
					<Typography variant="h3" className="mb-8">Inserisci la tua nuova password</Typography>

					<form onSubmit={formik.handleSubmit} className="flex w-full max-w-xl flex-col text-left">
						<div>
							<TextInput
								label="Nuova Password"
								onChange={formik.handleChange}
								id="password"
								name="password"
								type="password"
								placeholder="Nuova Password"
								isInvalid={!!(formik.errors.password && formik.touched.password && formik.errors.password === "Il campo 'nuova password' è obbligatorio")}
								errorMessage={formik.errors.password}
							/>
							{formik.values.password.length > 0 && (
								<div className="mt-4">
									<div className="mb-1 flex items-center gap-x-2">
										{passwordRequirementsMessage.includes("La password deve contenere almeno 10 caratteri") ? <FaTimes className="shrink-0 text-feedback-error" /> : <FaCheck className="text-feedback-success" />}
										<Typography variant="paragraph-span3" className={`text-greyscale-secondary opacity-50 ${!passwordRequirementsMessage.includes("La password deve contenere almeno 10 caratteri") && "line-through"}`}>La password deve contenere almeno 10 caratteri</Typography>
									</div>
									<div className="mb-1 flex items-center gap-x-2">
										{passwordRequirementsMessage.includes("La password deve contenere almeno un carattere maiuscolo") ? <FaTimes className="shrink-0 text-feedback-error" /> : <FaCheck className="text-feedback-success" />}
										<Typography variant="paragraph-span3" className={`text-greyscale-secondary opacity-50 ${!passwordRequirementsMessage.includes("La password deve contenere almeno un carattere maiuscolo") && "line-through"}`}>La password deve contenere almeno un carattere maiuscolo</Typography>
									</div>
									<div className="flex items-center gap-x-2">
										{passwordRequirementsMessage.includes("La password deve contenere almeno un numero") ? <FaTimes className="shrink-0 text-feedback-error" /> : <FaCheck className="text-feedback-success" />}
										<Typography variant="paragraph-span3" className={`text-greyscale-secondary opacity-50 ${!passwordRequirementsMessage.includes("La password deve contenere almeno un numero") && "line-through"}`}>La password deve contenere almeno un numero</Typography>
									</div>
								</div>
							)}
						</div>

						<div className="mt-6">
							<TextInput
								label="Conferma Password"
								onChange={formik.handleChange}
								id="confirmPassword"
								name="confirmPassword"
								type="password"
								placeholder="Conferma nuova password"
								isInvalid={!!(formik.errors.confirmPassword && formik.touched.confirmPassword)}
								errorMessage={formik.errors.confirmPassword}
							/>
						</div>

						<Button as="button" type="submit" className="mx-auto mt-8 w-fit" disabled={isPending}>Salva</Button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
