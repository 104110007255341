import { http } from "helpers2impact";

import config from "../../config";

// Types declaration
interface LoginDataParams { email: string, password: string, guest_identifier: string | null, recaptcha: string }
interface SignupDataParams {
	guest_identifier: string | null,
	email: string,
	firstname: string,
	lastname: string,
	password: string,
	news_and_contact_partner: number,
	friend_token: string | undefined,
	plan_slug: string,
	recaptcha: string,
}
interface ForgotPasswordParams { email: string, recaptcha: string }
interface ResetPasswordParams { token: string, new_password: string, recaptcha: string }

interface SignupResponse {
	token: string
}
interface LoginResponse {
	token: string
	message: string
}

// end types declaration

const login = async (loginData: LoginDataParams): Promise<LoginResponse> => {
	const { recaptcha, ...userData } = loginData;

	const { data } = await http(config.cookieName, config.loginPath, false)
		.post(`${config.apiUrl}/public/auth/local`, userData, { headers: { "X-Recaptcha-Token": recaptcha } });

	return data;
};

const signup = async (signupData: SignupDataParams): Promise<SignupResponse> => {
	const { recaptcha, ...userData } = signupData;

	const { data } = await http(config.cookieName, config.loginPath, false)
		.post(`${config.apiUrl}/public/users/registration`, userData, { headers: { "X-Recaptcha-Token": recaptcha } });

	return data.entities;
};

const forgotPassword = async ({ email, recaptcha }: ForgotPasswordParams) => {
	const { data } = await http(config.cookieName, config.loginPath, false)
		.post(`${config.apiUrl}/public/users/forgot-password`, { email }, { headers: { "X-Recaptcha-Token": recaptcha } });

	return data;
};

const resetPassword = async ({ token, new_password, recaptcha }: ResetPasswordParams) => {
	const { data } = await http(config.cookieName, config.loginPath, false)
		.put(`${config.apiUrl}/public/users/reset-password`, { token, new_password }, { headers: { "X-Recaptcha-Token": recaptcha } });

	return data.entities;
};

export { login, signup, forgotPassword, resetPassword };
