import * as yup from "yup";


const resetPswValidator = yup.object({
	password: yup
		.string().trim()
		.matches(/^(?=.{10,})/, "La password deve contenere almeno 10 caratteri")
		.matches(/(?=.*[A-Z])/, "La password deve contenere almeno un carattere maiuscolo")
		.matches(/(?=.*\d)/, "La password deve contenere almeno un numero")
		.required("Il campo 'nuova password' è obbligatorio"),
	confirmPassword: yup
		.string().trim().required("Conferma la nuova password")
		.oneOf([yup.ref("password")], "Le due password non corrispondono"),
});

export default resetPswValidator;
