import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import Cookies from "js-cookie";

import { Button, Checkbox, Loader, TextInput, Typography, useToast } from "design2impact";

import { signupValidators } from "./validators";
import { FaCheck, FaTimes } from "react-icons/fa";

import { signup } from "api/mutations";
import { fetchRegistrationPlanInfo } from "api/queries";

import config from "config";

interface FormikInitialValues {
	firstname: string,
	lastname: string,
	email: string,
	password: string,
	terms: boolean,
	news_and_contact_partner: boolean
}


const formInitialValues: FormikInitialValues = {
	firstname: "",
	lastname: "",
	email: "",
	password: "",
	terms: false,
	news_and_contact_partner: false,
};

const Signup = () => {
	const { showToast } = useToast();
	const { search } = useLocation();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const [recaptcha, setRecaptcha] = useState("");
	const [friendToken, setFriendToken] = useState("");
	const [currentPlanSlug, setCurrentPlanSlug] = useState("");
	const [passwordRequirementsMessage, setPasswordRequirementsMessage] = useState<string[]>([]);

	const searchParams = new URLSearchParams(search);
	const queryParamsSlug = searchParams.get("plan");

	const { data: planInfo, isLoading: planInfoLoading, error: planInfoError } = useQuery({
		queryKey: ["plans", queryParamsSlug, recaptcha],
		queryFn: () => fetchRegistrationPlanInfo(queryParamsSlug, recaptcha),
		enabled: !!recaptcha,
	});

	const {
		isPending: signupLoading,
		mutate: signupFn,
	} = useMutation({
		mutationFn: signup,
		onSuccess: (data) => {
			const domain = window.location.host.includes("localhost") ? "localhost" : ".start2impact.it";
			Cookies.set(config.cookieName, data.token, { expires: config.cookieExpirationDays, domain });
			window.location.href = planInfo?.slug ? `/checkout?plan=${planInfo.slug}` : "/checkout";
		},
		onError: () => showToast("La registrazione non è andata a buon fine. Controlla che l'email non sia già in uso.", "error"),
	});

	const handleSignup = async (data: FormikInitialValues) => {
		if (!executeRecaptcha) {
			showToast("La registrazione non è andata a buon fine. Controlla che l'email non sia già in uso.", "error");
			return;
		}

		const token = await executeRecaptcha("signup");
		const referrersId = Cookies.get("referrers");

		const signupData = {
			guest_identifier: referrersId || null,
			email: data.email,
			firstname: data.firstname,
			lastname: data.lastname,
			password: data.password,
			news_and_contact_partner: data.news_and_contact_partner ? 1 : 0,
			friend_token: friendToken ?? undefined,
			plan_slug: currentPlanSlug,
			recaptcha: token,
		};

		signupFn(signupData);
	};

	const formik = useFormik({
		initialValues: formInitialValues,
		onSubmit: (data) => {
			handleSignup(data);
		},
		validationSchema: signupValidators,
	});


	useEffect(() => {
		const searchParams = new URLSearchParams(search);
		const queryParamsSlug = searchParams.get("plan");
		const queryParamsFriendReferal = searchParams.get("token");

		if (queryParamsSlug) setCurrentPlanSlug(queryParamsSlug);
		if (queryParamsFriendReferal) setFriendToken(queryParamsFriendReferal);

		if (executeRecaptcha) {
			const getRecaptcha = async () => {
				const token = await executeRecaptcha("signup");
				setRecaptcha(token);
			};

			getRecaptcha();
		}
	}, [currentPlanSlug, search, executeRecaptcha]);

	useEffect(() => {
		signupValidators.validate({ password: formik.values.password }, { abortEarly: false })
			.then(() => {
				setPasswordRequirementsMessage([]);
			})
			.catch((errors) => {
				const passwordErrors = errors.inner
					.filter((error: any) => error.path === "password")
					.map((error: any) => error.message);

				setPasswordRequirementsMessage(passwordErrors);
			});
	}, [formik.values.password]);

	if (planInfoLoading || !recaptcha || planInfoError) return <Loader />;

	return (
		<>
			<Helmet>
				<title>Registrazione | start2impact</title>
			</Helmet>

			<main className="min-h-screen bg-ui-secondary bg-contain bg-bottom bg-no-repeat" style={{ backgroundImage: `url(${config.assetsPath}images/bg-wave-green.svg)` }}>
				<div className="flex h-16 items-center justify-center bg-[#000]">
					<img src="https://res.cloudinary.com/start2impact/image/upload/v1720185442/logo/logowhite.png" alt="" width="220" height="48" />
				</div>

				<section className="mx-auto flex max-w-[600px] flex-col px-4 pb-20 pt-10 sm:pt-20 md:!px-0">
					<h1 className="sr-only">Pagina di registrazione</h1>
					<Typography variant="h2" className="text-center">{planInfo?.registration_title}</Typography>
					<Typography variant="h4" className="mb-10 mt-2 text-center font-medium">Nessun addebito prima della fine dei 7 giorni!</Typography>

					<form onSubmit={formik.handleSubmit} className="mx-auto max-w-[500px]">

						<div className="flex flex-col gap-6">
							<TextInput
								onChange={formik.handleChange}
								type="text"
								id="firstname"
								name="firstname"
								placeholder="Inserisci Nome"
								isInvalid={!!(formik.errors.firstname && formik.touched.firstname)}
								errorMessage={formik.errors.firstname}
								label="Nome*"
							/>
							<TextInput
								onChange={formik.handleChange}
								type="text"
								id="lastname"
								placeholder="Inserisci Cognome"
								name="lastname"
								isInvalid={!!(formik.errors.lastname && formik.touched.lastname)}
								errorMessage={formik.errors.lastname}
								label="Cognome*"
							/>
							<TextInput
								onChange={formik.handleChange}
								type="email"
								id="email"
								placeholder="Inserisci Email"
								name="email"
								isInvalid={!!(formik.errors.email && formik.touched.email)}
								errorMessage={formik.errors.email}
								label="Email*"
							/>
							<div>
								<TextInput
									onChange={formik.handleChange}
									type="password"
									id="password"
									placeholder="Inserisci Password"
									name="password"
									isInvalid={!!(formik.errors.password && formik.touched.password && formik.errors.password === "Il campo 'password' è obbligatorio")}
									errorMessage={formik.errors.password}
									label="Password*"
								/>
								{formik.values.password.length > 0 && (
									<div className="mt-4">
										<div className="mb-1 flex items-center gap-x-2">
											{passwordRequirementsMessage.includes("La password deve contenere almeno 10 caratteri") ? <FaTimes className="shrink-0 text-feedback-error" /> : <FaCheck className="text-feedback-success" />}
											<Typography variant="paragraph-span3" className={`text-greyscale-secondary opacity-50 ${!passwordRequirementsMessage.includes("La password deve contenere almeno 10 caratteri") && "line-through"}`}>La password deve contenere almeno 10 caratteri</Typography>
										</div>
										<div className="mb-1 flex items-center gap-x-2">
											{passwordRequirementsMessage.includes("La password deve contenere almeno un carattere maiuscolo") ? <FaTimes className="shrink-0 text-feedback-error" /> : <FaCheck className="text-feedback-success" />}
											<Typography variant="paragraph-span3" className={`text-greyscale-secondary opacity-50 ${!passwordRequirementsMessage.includes("La password deve contenere almeno un carattere maiuscolo") && "line-through"}`}>La password deve contenere almeno un carattere maiuscolo</Typography>
										</div>
										<div className="flex items-center gap-x-2">
											{passwordRequirementsMessage.includes("La password deve contenere almeno un numero") ? <FaTimes className="shrink-0 text-feedback-error" /> : <FaCheck className="text-feedback-success" />}
											<Typography variant="paragraph-span3" className={`text-greyscale-secondary opacity-50 ${!passwordRequirementsMessage.includes("La password deve contenere almeno un numero") && "line-through"}`}>La password deve contenere almeno un numero</Typography>
										</div>
									</div>
								)}
							</div>

							<div>
								<div className="flex items-center gap-2">
									<Checkbox
										name="terms"
										id="terms"
										onChange={formik.handleChange}
										label={(
											<>
												Accetto&nbsp;
												<a href="https://www.start2impact.it/termini-e-condizioni/" target="_blank" rel="noreferrer" className="underline">Termini e Condizioni</a>
												&nbsp;e ho letto l&apos;Informativa&nbsp;
												<a href="https://www.start2impact.it/privacy-policy/" target="_blank" rel="noreferrer" className="underline">Privacy</a>
												*
											</>
										)}
									/>
								</div>
								{
									formik.errors.terms && formik.touched.terms &&
									<Typography variant="paragraph-span3" className="mt-2 block text-feedback-error">{formik.errors.terms}</Typography>
								}
								<div className="mt-6 flex gap-2">
									<Checkbox
										id="news_and_contact_partner"
										name="news_and_contact_partner"
										label="Do il consenso a ricevere informazioni sui nuovi corsi disponibili e ad essere messo in contatto con le aziende partner"
										onChange={formik.handleChange}
									/>
								</div>
								{
									formik.errors.news_and_contact_partner && formik.touched.news_and_contact_partner &&
									<Typography variant="paragraph-span3" className="mt-2 block text-feedback-error">{formik.errors.news_and_contact_partner}</Typography>
								}
							</div>
						</div>

						<div className="flex justify-center">
							<Button as="button" disabled={signupLoading} type="submit" className="mt-10 w-fit">continua</Button>
						</div>

						<Link to={{ pathname: config.loginPath }} className="mb-10 mt-5 block text-center text-brand-primary sm:mb-20">
							<Typography variant="paragraph-span1">Hai già un account? ACCEDI</Typography>
						</Link>
					</form>
				</section>
			</main>
		</>
	);
};

export default Signup;
